import React from "react";
import Link from "next/link";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import CircleIcon from "@mui/icons-material/Circle";
import Divider from "@mui/material/Divider";
import { monthConvertDMY } from "@/features/monthConvert";

function NewsList({ data }: any) {
  return (
    <div className="news-list">
      <Grid
        container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 12, sm: 12, md: 12 }}
        alignItems="center"
      >
        {data.map((value: any, index: any) => {
          if (index <= 2) {
            return (
              <Grid xs={12} sm={12} md={12} key={index} alignItems="center">
                <Link href={`/NewsDetail/${value.news_id}`}>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    justifyContent="flex-start"
                    alignItems="center"
                    className="news-list_inner"
                  >
                    <div className="news-date col-12 col-lg-2">
                      <CircleIcon
                        sx={{
                          fontSize: "12px",
                          mr: 2,
                          mb: 0.5,
                          color: "#0179CC",
                        }}
                      />
                      {monthConvertDMY(value.post_date)}
                    </div>
                    <Divider orientation="vertical" flexItem></Divider>
                    <div className="news-subject col-12 col-lg-9">
                      {value.topic}
                    </div>
                  </Stack>
                </Link>
              </Grid>
            );
          } else {
            return null;
          }
        })}
      </Grid>
    </div>
  );
}

export default NewsList;
